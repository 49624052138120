<template>
  <v-app>
    <Navigation />
    <v-main class="mb-15">
      <v-container>
        <v-row class="mb-15 fill-height">
          <v-col>
            <v-row>
              <v-col>
                <h1>Leads Por Medio</h1>
              </v-col>
            </v-row>
            <v-row style="padding-left: 30px; margin-top: 25px"> </v-row>
            <v-row class="mb-15" style="padding-left: 30px">
              <v-col cols="3">
                <h3>Selecciona el rango de fechas</h3>
                <v-date-picker
                  style="width: 100%"
                  v-model="dates"
                  range
                  locale="es"
                ></v-date-picker>
                <h3 class="mb-2">
                  Selecciona el ramo y canal para determinar el medio
                </h3>
                <v-select
                  v-model="ramo"
                  filled
                  dense
                  :items="ramosPermitidos"
                  item-value="ramo"
                  item-text="ramo"
                  label="Ramo"
                  no-data-text="Sin datos para mostrar"
                ></v-select>
                <v-select
                  v-model="canal"
                  filled
                  dense
                  :items="canalesPermitidos"
                  item-value="canal"
                  item-text="canal"
                  label="Canal"
                  no-data-text="Sin datos para mostrar"
                ></v-select>
                <v-select
                  v-model="medio"
                  filled
                  dense
                  :items="campaniasFiltradas"
                  item-value="medio"
                  item-text="medio"
                  label="Medio"
                  :no-data-text="
                    !canal || !ramo
                      ? 'Debes seleccionar canal y ramo antes'
                      : 'Sin datos para mostrar'
                  "
                ></v-select>
                <v-btn
                  rounded
                  color="primary"
                  style="width: 100%"
                  dark
                  @click="getReporte()"
                  >Consultar</v-btn
                >
                <v-btn
                  rounded
                  color="primary"
                  style="width:100%;margin-top:20px;"
                  :disabled="bloquear"
                  ><download-csv
                    :data="reportDownload"
                    name="Reporte_Leads_Medio.csv"
                  >
                    Descargar Información
                  </download-csv></v-btn
                >
              </v-col>
              <v-col cols="9">
                <div>
                  <div v-if="loading" style="text-align: center">
                    <h2 clas="pb-4">Cargando Reporte....</h2>
                    <br /><br />
                    <md-progress-spinner
                      :md-diameter="100"
                      :md-stroke="10"
                      md-mode="indeterminate"
                    ></md-progress-spinner>
                  </div>
                </div>

                <v-data-table
                  :headers="columns"
                  :items="reporteData"
                  item-key="name"
                  class="elevation-1"
                  no-data-text="Sin datos para mostrar"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "../../../../components/Nav.vue";
import { mainAxios } from "../../../../mainAxios";

import moment from "moment-timezone";
// ADMIN FINANZAS y DIRECTOR

export default {
  components: {
    Navigation,
  },

  data() {
    return {
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem("roles")),
      agenteUuid: localStorage.getItem("agenteUuid"),
      loading: false,
      bloquear: true,
      reporteData: [],
      reportDownload: [],
      campanias: [],
      canales: [],
      ramos: [],
      usuario: null,
      canal: "",
      ramo: "",
      medio: "",

      columns: [
        { text: "ID", value: "id" },
        { text: "Medio", value: "medio" },
        { text: "Ramo", value: "ramo" },
        { text: "Canal", value: "canal" },
        { text: "Producto", value: "producto" },
        { text: "Nombre", value: "nombre" },
        { text: "Apellido Paterno", value: "apellidopaterno" },
        { text: "Apellido Materno", value: "apellidomaterno" },
        { text: "Teléfono", value: "telefono" },
        { text: "Correo", value: "correo" },
        { text: "Fecha de creación", value: "fecha_lead" },
      ],
    };
  },

  computed: {
    puedeVerTodo() {
      const roles = JSON.parse(localStorage.roles);
      if (!roles) return false;

      const rolesPermitidos = ["ADMIN", "FINANZAS", "DIRECTOR"];
      let result = false;
      rolesPermitidos.forEach((e) => {
        if (roles.find((rol) => rol.rol === e)) result = true;
      });

      return result;
    },

    canalesPermitidos() {
      if (this.puedeVerTodo) return this.canales;
      if (!this.usuario || !this.canales.length) return [];
      const result = [];

      this.usuario.canales.forEach((canalUsuario) => {
        result.push(this.canales.find((e) => e.id == canalUsuario));
      });

      return result;
    },

    ramosPermitidos() {
      if (this.puedeVerTodo) return this.ramos;
      if (!this.usuario || !this.ramos.length) return [];
      const result = [];

      this.usuario.ramos.forEach((ramoUsuario) => {
        result.push(this.ramos.find((e) => e.id == ramoUsuario));
      });

      return result;
    },

    campaniasFiltradas() {
      if (!this.canal || !this.ramo) return [];

      let result = [];
      result = this.campanias.filter(
        (e) => e.canal == this.canal && e.ramo == this.ramo
      );

      result = result.filter((e, index) => {
        return result.findIndex((z) => z.medio == e.medio) == index;
      });

      return result;
    },
  },

  mounted() {
    this.getCampanias();
    this.getRamos();
    this.getCanales();
    this.getUsuario();
  },

  methods: {
    getCampanias() {
      this.loading = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios
        .get("/v1/campania/lead-segurify/list", config)
        .then(({ data }) => {
          this.campanias = data;
          this.loading = false;
        });
    },

    getCanales() {
      this.loading = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios.get("/v1/canal/list", config).then(({ data }) => {
        this.canales = data;
        this.loading = false;
      });
    },

    getRamos() {
      this.loading = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios.get("/v1/ramo/list", config).then(({ data }) => {
        this.ramos = data;
        this.loading = false;
      });
    },

    getUsuario() {
      this.loading = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios
        .get(`/v1/agente/uuid/${localStorage.getItem("agenteUuid")}`, config)
        .then(({ data }) => {
          this.usuario = data;
          this.loading = false;
        });
    },

    getReporte() {
      this.loading = true;
      this.reportDownload = [];
      this.reporteData = [];
      const reporteUrl = "/v1/lead-segurify/reporte";
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      let inicioUTC = null;
      let finUTC = null;

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment.utc(this.dates[0]).format("YYYY-MM-DD");
          finUTC = moment
            .utc(this.dates[1])
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD");
        } else {
          inicioUTC = moment.utc(this.dates[0]).format("YYYY-MM-DD");
          finUTC = moment
            .utc(this.dates[0])
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD");
        }
      }

      const fechas = [inicioUTC, finUTC];
      const body = { fechas, medio: this.medio };

      mainAxios.post(reporteUrl, body, config).then(({ data }) => {
        const payload = data
          .filter((e) => e.ramo == this.ramo && e.canal == this.canal)
          .map((e) => ({
            ...e,
            ...JSON.parse(e.request_json),
            fecha_lead: moment.utc(e.fecha_lead).format("DD/MM/YYYY"),
          }));
        payload.forEach((element) => {
          let payloadDataTable = {};
          let payloadDownload = {};
          this.columns.forEach((column) => {
            payloadDataTable[column.value] =
              element[column.value] || "No disponible";
            payloadDownload[column.text] =
              element[column.value] || "No disponible";
          });
          this.reporteData.push(payloadDataTable);
          this.reportDownload.push(payloadDownload);
        });
      });

      this.bloquear = false;
      this.loading = false;
    },
  },
};
</script>
